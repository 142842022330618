body {
    margin: 0;
}
.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
}
#map {
    flex-grow: 1;
}
#info {
    color: whitesmoke;
    background-color: #333333;
    min-height: 120px;
    min-width: 240px;
    padding: 8px;
}
#info pre {
    margin: 0;
}

.tag {
    color: white;
    width: 64px;
    height: 16px;
    /*text-align: center;*/
    vertical-align: center;
    position: absolute; /* TODO: fix positioning */
    /*left: -22px;   !* this is a hack *!*/
    top: -14px;      /* this too :( */
    left: 20px;
    /*background: black;*/
}
.tag-hidden {
    display: none;
}

@media(orientation: portrait) {
    .container {
        flex-direction: column;
    }
}
